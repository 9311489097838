import React from "react";

import classNames from "classnames";
import ReactPlayer from "react-player";

import { media } from "../NSEmbed.module.scss";

// For Platform options see:
// https://www.npmjs.com/package/react-player

const DailyMotion = ({ urlUri, alt }) => {
  return (
    <div
      className={classNames(media, "rca-embed-platform dailymotion")}
      title={alt || urlUri}
    >
      <ReactPlayer
        url={urlUri}
        config={{
          dailymotion: {
            params: {
              "ui-theme": "light",
            },
          },
        }}
        controls
        playing={false}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default DailyMotion;
