import React, { useState } from "react";

const Sirv = ({ urlUri, alt }) => {
  // useEffect(() => {
  //   const hasScript = document.getElementById('sirvscript');

  //   let check = false;

  //   const sirvLoad = () => {
  //     requestAnimationFrame(() => {
  //       requestAnimationFrame(() => {
  //         window.dispatchEvent(new Event('tile-loaded'));
  //       });
  //     });
  //   };

  //   let timer;

  //   if (!hasScript) {
  //     const script = document.createElement('script');

  //     script.id = 'sirvscript';
  //     script.src = 'https://scripts.sirv.com/sirv.js';
  //     script.async = true;
  //     script.onload = () => {
  //       sirvLoad();
  //     };

  //     if (!check) {
  //       document.body.appendChild(script);
  //       check = true;
  //     }
  //   } else {
  //     timer = setTimeout(() => {
  //       sirvLoad();
  //     }, 500);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  const [loaded] = useState(true);

  // useEffect(() => {
  //   const scriptTag = document.createElement('script');
  //   scriptTag.src = 'https://scripts.sirv.com/sirv.js';
  //   scriptTag.id = 'sirvscript';
  //   scriptTag.addEventListener('load', () => setLoaded(true));
  //   if (scriptTag instanceof HTMLElement) {
  //     document.body.appendChild(scriptTag);
  //   }

  //   return () => {
  //     window.Sirv = null;
  //     let script = document.getElementById('sirvscript');
  //     script?.parentNode?.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      {loaded ? (
        <div
          style={{ width: "auto", height: "611px" }}
          className="rca-embed-platform sirv"
          title={alt || urlUri}
        >
          {/* <div className="Sirv" data-src={urlUri}></div> */}
          <iframe
            src={urlUri}
            width={"100%"}
            height={"611px"}
            frameBorder="0"
            allowFullScreen
            title="Sirv"
          ></iframe>
        </div>
      ) : (
        <div>Sirv script not loaded</div>
      )}
    </>
  );
};

export default Sirv;
