import React from "react"; // eslint-disable-line no-unused-vars
import loadable from "@loadable/component";
import * as styles from "./Modelviewer.module.scss";

// const ModelviewerEmbed = loadable(() => import("./ModelviewerEmbed"), {
//   ssr: false,
// });

const ModelviewerEmbed = loadable(
  async () => (await import("./ModelviewerEmbed")).ModelviewerEmbed,
  {
    fallback: <>loading</>,
  },
);

const Modelviewer = ({ url }) => {
  return (
    <div className={styles.modelviewerWrapper}>
      <ModelviewerEmbed url={url} />
    </div>
  );
};

export { Modelviewer };
