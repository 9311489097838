import React from "react";

import classNames from "classnames";

import { media } from "../NSEmbed.module.scss";

const InDesign = ({ urlUri, alt }) => {
  return (
    <div
      className={classNames(media, "rca-embed-platform issuu")}
      style={{ "--embed-aspect": "50%" }}
    >
      <iframe
        // allowFullScreen
        allow="fullscreen"
        src={urlUri}
        title={alt || urlUri}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </div>
  );
};

export default InDesign;
