import React from "react";

import ReactPlayer from "react-player";

// For Platform options see:
// https://www.npmjs.com/package/react-player

const SoundCloud = ({ urlUri, alt }) => {
  return (
    <div className="rca-embed-platform soundcloud" title={alt || urlUri}>
      <ReactPlayer
        url={urlUri}
        config={{
          soundcloud: {
            options: {
              autoplay: false,
              color: "#000000", // Does not work, possibly only for Flash Player
              sharing: true,
              download: true,
              show_artwork: false,
            },
          },
        }}
        controls
        playing={false}
        width="100%"
        height="30%"
      />
    </div>
  );
};

export default SoundCloud;
