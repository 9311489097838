import React, { useEffect, useState } from "react";

import classNames from "classnames";
import ReactPlayer from "react-player";

import { media, vimeo } from "../NSEmbed.module.scss";

// For Platform options see:
// https://www.npmjs.com/package/react-player

const Vimeo = ({ urlUri, alt, auto, muted, absolute }) => {
  const [pt, setPt] = useState("56.25%");

  useEffect(() => {
    fetch(`https://vimeo.com/api/oembed.json?url=${urlUri}`)
      .then((resp) => resp.json())
      .then(({ width, height }) => {
        if (width && height) {
          setPt(`${(height / width) * 100}%`);
        }
      });
  }, [urlUri]);

  const isPlaying = auto ? true : false;
  const isMuted = muted ? true : false;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div
      className={classNames(media, "rca-embed-platform vimeo")}
      style={{ "--embed-aspect": `${pt}` }}
      title={alt || urlUri}
    >
      {isClient && (
        <ReactPlayer
          url={urlUri}
          config={{
            vimeo: {
              playerOptions: {
                byline: true,
                color: "#000099",
                transparent: 1,
              },
            },
          }}
          loop={true}
          controls={true}
          muted={isMuted}
          playing={isPlaying}
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default Vimeo;
