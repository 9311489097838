"use client";

import React, { useMemo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import DailyMotion from "./embeds/DailyMotion";
import Facebook from "./embeds/Facebook";
import InDesign from "./embeds/InDesign";
import Issuu from "./embeds/Issuu";
import ShapeDiver from "./embeds/ShapeDiver";
import Sirv from "./embeds/Sirv";
import Sketchfab from "./embeds/Sketchfab";
import SoundCloud from "./embeds/SoundCloud";
import Spotify from "./embeds/Spotify";
import Streamable from "./embeds/Streamable";
import Twitch from "./embeds/Twitch";
import Vimeo from "./embeds/Vimeo";
import Wistia from "./embeds/Wistia";
import Youtube from "./embeds/Youtube";
import { Modelviewer } from "./embeds/Modelviewer";

const SERVICES = {
  INDESIGN: "indesign",
  ISSUU: "issuu",
  SPOTIFY: "spotify",
  SKETCHFAB: "sketchfab",
  YOUTUBE: "youtube",
  FACEBOOK: "facebook",
  TWITCH: "twitch",
  SOUNDCLOUD: "soundcloud",
  STREAMABLE: "streamable",
  VIMEO: "vimeo",
  WISTIA: "wistia",
  DAILYMOTION: "dailymotion",
  SIRV: "sirv",
  SHAPEDIVER: "shapediver",
  MODELVIEWER: "modelviewer",
  NOT_SUPPORTED: "Not Supported",
};

const NSEmbed = ({ mediaUrl, className, alt }) => {
  /* InDesign */
  // https://indd.adobe.com/view/d0be3831-b056-4732-998c-20321c900c5e

  /* Issuu */
  // https://issuu.com/sweetpaulmagazine/docs/spm_su19_digital
  // https://issuu.com/sheilalawson/docs/1_reception_layout_34/s/10623336
  // issuu.com/sheilalawson/docs/1_reception_layout_34?fr=sNGRjOTE1MDA1NDU

  // <iframe allowfullscreen allow="fullscreen" style="border:none;width:100%;height:326px;" src="//e.issuu.com/embed.html?d=1_reception_layout_34&u=sheilalawson"></iframe>

  /* Spotify */
  // Track
  // https://open.spotify.com/track/2EqlS6tkEnglzr7tkKAAYD
  // spotify:track:2EqlS6tkEnglzr7tkKAAYD

  // ALBUM
  // https://open.spotify.com/album/4RuzGKLG99XctuBMBkFFOC
  // spotify:album:1DFixLWuPkv3KT3TnV35m3

  // PODCAST SHOW
  // https://open.spotify.com/show/1amnggjPO42W9l9R5xXodQ?si=gNbcAWmBRXiBCKbwnqKuOg
  // spotify:show:3vikAuFxKVNe2GBZC61IYD

  // PODCAST EPISODE
  // https://open.spotify.com/episode/7ID7aPKQWy5sKWBAviIwMD?si=-cchsVEwQYeuK4garVATEw
  // spotify:episode:5shxpdGkCaERVmZSjpbfbk

  // PLAYLIST
  // https://open.spotify.com/playlist/3ALgoNp8qNdvGq6kVC2n9x?si=OEAayxJbS92Y_Y7tutNvOg
  // spotify:playlist:3ALgoNp8qNdvGq6kVC2n9x

  /* Sketchfab */
  // https://sketchfab.com/3d-models/chair-eames-shell-replica-001-14ebab7928ba4299942f041375dd85c5
  // https://skfb.ly/6R8QV

  /* Youtube */
  // https://youtu.be/xNk7fAqYLiw

  /* Facebook */
  // https://www.facebook.com/FacebookDevelopers/videos/10152454700553553/

  /* Twitch */
  // https://www.twitch.tv/videos/106400740

  /* Sound Cloud */
  // https://soundcloud.com/miami-nights-1984/accelerated

  /* Streamable */
  // https://streamable.com/ifjh

  /* Vimeo */
  // https://vimeo.com/90509568

  /* Wistia */
  // https://home.wistia.com/medias/e4a27b971d

  /* Daily Motion */
  // https://www.dailymotion.com/video/x5e9eog

  /* Embeds not supported for WiP 2022 */
  // Daily Motion
  // Wistia
  // Streamable
  // ShapeDiver
  // Facebook

  const service = useMemo(() => {
    let services;

    // InDesign
    if (mediaUrl.includes("://indd.adobe.com/view/"))
      services = SERVICES.INDESIGN;

    // Issuu
    if (
      mediaUrl.includes("://issuu.com/") ||
      mediaUrl.includes("://amp.issuu.com")
    )
      services = SERVICES.ISSUU;

    // Spotify
    if (
      mediaUrl.includes("://open.spotify.com/track/") ||
      mediaUrl.includes("spotify:track:") ||
      mediaUrl.includes("://open.spotify.com/album/") ||
      mediaUrl.includes("spotify:album:") ||
      mediaUrl.includes("://open.spotify.com/show/") ||
      mediaUrl.includes("spotify:show:") ||
      mediaUrl.includes("://open.spotify.com/episode/") ||
      mediaUrl.includes("spotify:episode:") ||
      mediaUrl.includes("://open.spotify.com/playlist/") ||
      mediaUrl.includes("spotify:playlist:")
    )
      services = SERVICES.SPOTIFY;

    // Sketchfab
    if (
      mediaUrl.includes("sketchfab.com/3d-models/") ||
      mediaUrl.includes("skfb.ly")
    )
      services = SERVICES.SKETCHFAB;

    // Youtube
    if (
      mediaUrl.includes("://youtube.com/shorts") ||
      mediaUrl.includes("://www.youtube.com/watch") ||
      mediaUrl.includes("://www.youtube.com/playlist") ||
      mediaUrl.includes("://youtu.be") ||
      mediaUrl.includes("://www.youtube.com/embed/live_stream") ||
      (mediaUrl.includes("youtube.com/user/") && mediaUrl.includes("/live"))
    )
      services = SERVICES.YOUTUBE;

    // Facebook
    // if (
    //   mediaUrl.includes("://www.facebook.com/") &&
    //   mediaUrl.includes("/videos/")
    // )
    //   services = SERVICES.FACEBOOK

    // Twitch
    if (mediaUrl.includes("://www.twitch.tv/")) services = SERVICES.TWITCH;

    // Sound Cloud
    if (mediaUrl.includes("://soundcloud.com/")) services = SERVICES.SOUNDCLOUD;

    // Streamable
    // if (mediaUrl.includes("://streamable.com/")) services = SERVICES.STREAMABLE

    // Vimeo
    if (mediaUrl.includes("://vimeo.com/")) services = SERVICES.VIMEO;

    // Wistia
    // if (
    //   mediaUrl.includes(".wistia.com/medias/") ||
    //   (mediaUrl.includes(".wistia") && mediaUrl.includes("/embed/iframe/"))
    // )
    //   services = SERVICES.WISTIA

    // Daily Motion
    // if (mediaUrl.includes("://www.dailymotion.com/video/"))
    //   services = SERVICES.DAILYMOTION

    // Sirv
    if (mediaUrl.includes(".sirv.com/")) services = SERVICES.SIRV;

    // ShapeDiver
    //   if (mediaUrl.includes("://viewer.shapediver.com/"))
    //     return SERVICES.SHAPEDIVER
    if (mediaUrl.includes(".glb") || mediaUrl.includes(".gltf"))
      services = SERVICES.MODELVIEWER;

    if (!services || services === null || services === undefined)
      services = SERVICES.NOT_SUPPORTED;

    return services;
  }, [mediaUrl]);

  return (
    <div className={classNames("rca-embed", className)}>
      {service === SERVICES.INDESIGN && (
        <InDesign urlUri={mediaUrl} alt={alt} />
      )}
      {service === SERVICES.ISSUU && <Issuu urlUri={mediaUrl} alt={alt} />}
      {service === SERVICES.SPOTIFY && <Spotify urlUri={mediaUrl} alt={alt} />}
      {service === SERVICES.SKETCHFAB && <Sketchfab urlUri={mediaUrl} />}
      {service === SERVICES.YOUTUBE && <Youtube urlUri={mediaUrl} alt={alt} />}
      {service === SERVICES.FACEBOOK && (
        <Facebook urlUri={mediaUrl} alt={alt} />
      )}
      {service === SERVICES.TWITCH && <Twitch urlUri={mediaUrl} alt={alt} />}
      {service === SERVICES.SOUNDCLOUD && (
        <SoundCloud urlUri={mediaUrl} alt={alt} />
      )}
      {service === SERVICES.STREAMABLE && (
        <Streamable urlUri={mediaUrl} alt={alt} />
      )}
      {service === SERVICES.VIMEO && <Vimeo urlUri={mediaUrl} alt={alt} />}
      {service === SERVICES.WISTIA && <Wistia urlUri={mediaUrl} alt={alt} />}
      {service === SERVICES.DAILYMOTION && (
        <DailyMotion urlUri={mediaUrl} alt={alt} />
      )}
      {service === SERVICES.SIRV && <Sirv urlUri={mediaUrl} alt={alt} />}
      {service === SERVICES.SHAPEDIVER && (
        <ShapeDiver urlUri={mediaUrl} alt={alt} />
      )}
      {service === SERVICES.MODELVIEWER && <Modelviewer url={mediaUrl} />}

      {service === SERVICES.NOT_SUPPORTED && (
        <div>
          <p>
            <code>{mediaUrl}</code>
          </p>
          {/* <p>
            <strong>{service}</strong>
          </p> */}
        </div>
      )}
    </div>
  );
};

export { NSEmbed };
